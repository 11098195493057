import React, {useState} from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useRoutes } from 'react-router-dom';
// components
import Login from './Components/Login2';
import Feed from './Components/Feed';

import Navbar from "./Components/Navbar";
import First from "./Components/FirstDOCS";
import Second from "./Components/SecondDOCS";
import Third from "./Components/Third";
import Fourth from "./Components/Fourth";
import Fifth from "./Components/Fifth";
import Sixth from './Components/Sixth';
import Seventh from './Components/Search/Search'
import SideBar from './Components/Sidebar';
import sidebar_menu from './constants/sidebar-menu';

import axios from "axios";
// context api


const App = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [token, setToken] = useState();
    const [ userData, setUserData] = useState({
    token: undefined,
    user: undefined
    });

  const checkLoggedIn = () => {
    let tokenas = localStorage.getItem("token");
    if(tokenas === null){
      console.log("FalsE")
      return false;
    }
    else {
      console.log("TruE"+tokenas)
      return true;
    }
  }


    if(!checkLoggedIn())
    {
      return <Login setToken={setToken} />
    }
    return(
      <BrowserRouter>
        <div className='dashboard-container'>
        <SideBar menu={sidebar_menu} />
          
          <div className='dashboard-body'>
              <Routes>
            <Route path="/" element={<Feed token={token}/>}/>
            <Route path="/first" element={<First token={token}/>}/>
            <Route path="/second" element={<Second token={token}/>}/>
            <Route path="/third" element ={<Third token={token}/> } />
            <Route path="/fourth" element ={<Fourth token={token}/> } />
            <Route path="/fifth" element ={<Fifth token={token}/> } />
            <Route path="/sixth" element ={<Sixth token={token} />} />
            <Route path="/seventh" element ={<Seventh token={token} />} />
          </Routes>
          </div>
          </div>
      </BrowserRouter>


    )
  }




export default App;
