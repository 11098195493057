import React, { useState}  from 'react';
import './Post.css';
import { Alert } from 'react-alert'

import { saveAs } from 'file-saver';
import axios from "axios";
import './modal.css';
import config from "../config.js";
class PostTrack extends React.Component {

constructor(props)
{
  super(props);
  this.state = {
   
    money: this.props.money,
    time: this.props.time,
    dateOfBirth: "",
    filename: this.props.name,
    fileNameBack: this.props.nameBack,
    isFinished: null

  }
}
   


    render()
    {
    console.log("HERE IS SIGNED")
    console.log(this.props.isSigned)
    const signed = this.props.isSigned ? "Bəli" : "Xeyr";
    var date = new Date(this.props.createdAt);
    return (
        <div className="postWaiting">
            <div className="postTop">

            </div>
            <div className="postBottom">
                <p>Ad: {this.props.name}</p>
            </div>
            <div className="postBottom">
                <p>Soyad: {this.props.surname}</p>
            </div>
            <div className="postBottom">
                <p>Ata adı: {this.props.father}</p>
            </div>
            <div className="postBottom">
                <p>Sənədin Nömrəsi: {this.props.doc}</p>
            </div>
            <div className="postBottom">
                <p>FIN: {this.props.fin}</p>
            </div>
            <div className="postBottom">
                <p>Məbləğ: {this.props.money}</p>
            </div>
            <div className="postBottom">
                <p>Müddət: {this.props.time}</p>
            </div>
            
            <div className="postBottom">
                <p>Doğum Tarixi: {this.props.date
                }</p>
            </div>
            <div className="postBottom">
                <p>İmzalayıb: {signed}</p>
            </div>
            <div className="postBottom">
                <p>Tarix: {
                    date.getHours() + ":" + date.getMinutes() +", "+date.toLocaleDateString()
                }</p>
            </div>
            <div className="postImage">
            </div>

        </div>
    )
}
}

export default PostTrack;
