import React, {useRef} from "react";
import "./table.css";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === "imperial" || sortConfig.key === "metric") {
          a[sortConfig.key] = a.height[sortConfig.key];
          b[sortConfig.key] = b.height[sortConfig.key];
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

const Table = (props) => {
  const tableRef = useRef(null);

  const { items, requestSort, sortConfig } = useSortableData(props.data);
  const getClassNamesFor = (key) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };
  return (
    <div>
      <DownloadTableExcel
                    filename="client table"
                    sheet="clients"
                    currentTableRef={tableRef.current}
                >

                   <button> Export excel </button>

                </DownloadTableExcel>
    <table ref={tableRef}>
      <thead>
        <tr>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => requestSort("name")}
            className={getClassNamesFor("name")}
            id="name"
          >
            Ad
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => requestSort("life_span")}
            className={getClassNamesFor("life_span")}
            id="life_span"
          >
            Soyad
          </th>
          <th
            style={{ cursor: "pointer" }}
            className={getClassNamesFor("imperial")}
            id="imperial"
          >
            Mobil Nömrə
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => requestSort("metric")}
            className={getClassNamesFor("metric")}
            id="metric"
          >
           Müddət
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => requestSort("metric")}
            className={getClassNamesFor("metric")}
            id="metric"
          >
           Məbləğ
          </th>
          <th
            id="metric"
          >
           Tarix
          </th>
          <th
            id="metric"
          >
           Mərhələ
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const date = new Date(item.createdAt)
          console.log(item)
          return (<tr key={item._id}>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            <td>{item.mobileNumber}</td>
            <td>{item.time}</td>
            <td>{item.amountOfMoney}</td>
            <td>{date.getHours() + ":" + date.getMinutes() +", "+date.toLocaleDateString()}</td>
            <td>{item.stage}</td>
          </tr>
      )})}
      </tbody>
    </table>
    </div>
  );
  /*
  return (
    <div>
      <DownloadTableExcel
                    filename="client table"
                    sheet="clients"
                    currentTableRef={tableRef.current}
                >

                   <button> Export excel </button>

                </DownloadTableExcel>
    <table ref={tableRef}>
      <thead>
        <tr>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => requestSort("name")}
            className={getClassNamesFor("name")}
            id="name"
          >
            Ad
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => requestSort("life_span")}
            className={getClassNamesFor("life_span")}
            id="life_span"
          >
            Soyad
          </th>
          <th
            style={{ cursor: "pointer" }}
            className={getClassNamesFor("imperial")}
            id="imperial"
          >
            Mobil Nömrə
          </th>
          <th
            style={{ cursor: "pointer" }}
            onClick={() => requestSort("metric")}
            className={getClassNamesFor("metric")}
            id="metric"
          >
           İlk Məbləğ
          </th>
          <th id="breed_group">Son Məbləğ</th>
          <th
            id="metric"
          >
           Razılıq Ərizəsi
          </th>
          <th
            id="metric"
          >
           Müqavilə
          </th>
          <th
            id="metric"
          >
           Zamin
          </th>
          <th
            id="metric"
          >
           Tarix
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const date = new Date(item.createdAt)
          console.log(item)
          return (<tr key={item._id}>
            <td>{item.user_id.firstName}</td>
            <td>{item.user_id.lastName}</td>
            <td>{item.user_id.mobileNumber}</td>
            <td>{item.fileID ? item.fileID.amountOfMoney : "Mövcud deyil"}</td>
            <td>{item.contractFile ? item.contractFile.amount : "Mövcud deyil"}</td>
            
            <td>{item.isSigned ? "Imzalanıb" : "Imzalanmayıb"}</td>
            <td>{item.isContracted ? "Imzalanıb" : "Imzalanmayıb"}</td>
            <td>{item.isZamin ? "Imzalanıb" : "Imzalanmayıb"}</td>
            <td>{date.getHours() + ":" + date.getMinutes() +", "+date.toLocaleDateString()}</td>
          </tr>
      )})}
      </tbody>
    </table>
    </div>
  );
  */
};

export default Table;
