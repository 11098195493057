import React, { useState}  from 'react';
import './Post.css';
import { Alert } from 'react-alert'

import { saveAs } from 'file-saver';
import axios from "axios";
import './modal.css';
import config from "../config.js";
class Post extends React.Component {

constructor(props)
{
  super(props);
  this.state = {
    showModal: false,
    firstName: "",
    lastName: "",
    fatherName: "",
    docID: "",
    FIN: "",
    key: this.props.postID,
    mobileNumber: this.props.mobileNumber,
    money: this.props.money,
    time: this.props.time,
    dateOfBirth: "",
    filename: this.props.name,
    fileNameBack: this.props.nameBack,
    isFinished: null

  }
  this.showModal = this.showModal.bind(this);
}
    getPDF ()
    {
      axios.get("http://"+config.ip+"/api/files/download/"+this.props.name, {
        responseType: "blob",
        headers: {
          'x-access-token': this.props.token
        }
      })
      .then(response=>
        {
          console.log(response)
          saveAs(response.data, "test.jpg")
        }
      )

      axios.get("http://"+config.ip+"/api/files/download/"+this.props.nameBack, {
        responseType: "blob",
        headers: {
          'x-access-token': this.props.token
        }
      })
      .then(response=>
        {
          console.log(response)
          saveAs(response.data, "test.jpg")
        }
      )
      //fetch("http://192.168.19.1:3000/files/download/"+name,
      //  {
      //      headers: {
      //        'Accept': 'application/pdf'
      //      }
      //  })
      //    .then((res) =>
      //    {
      //      console.log("res");
      //      console.log(res);

      //      saveAs(res.url, "test.pdf");
      //    }
      //  );
    }

    showModal()
    {
      if(this.state.showModal)
      {
        this.setState({showModal: false})
      }
      else{
        this.setState({showModal: true})
      }
    }
    
    sendPost(state)
    {
        if(state.firstName == "" || state.lastName == "" || state.mobileNumber == "" || state.fatherName == "" || state.docID == "" || state.FIN == "" || state.money == "" || state.dateOfBirth == "" || state.time == "" || state.filename == "" )
        {
          alert("Bütün xanaların doldurulduğundan əmin olun");
        }
        console.log("Send post "+this.props.token)
        
        debugger
        axios.post("http://"+config.ip+"/api/posts/newPost", {
          token: this.props.token,
          idOfUser: state.mobileNumber,
          name: state.firstName,
          surname: state.lastName,
          fatherName: state.fatherName,
          docID: state.docID,
          finNumber: state.FIN,
          amountOfMoney: state.money,
          time: state.time,
          dateOfBirth: state.dateOfBirth,
          filename: state.filename,
          key: state.key
        }).then(res =>
          {
            this.setState({showModal: false});
            debugger
            window.location.reload(false);
          })
        .catch(err => console.log("Error in send post" + err))
    }

    deleteFile(filename, filenameBack) {
      axios.post("http://" + config.ip + "/api/files/deleteFile", {
        fileName: filename,
        token: this.props.token,
        fileNameBack: filenameBack
      }).then(res => {
        console.log(res)
        window.location.reload()
      })
      .catch(err => console.log("Error sending post" + err))
    }
    render()
    {
      const showHideClassName = this.state.showModal ? "modal display-block" : "modal display-none";
      var date = new Date(this.props.date);

    return (
        <div className="postWaiting">
            <div className="postTop">

            </div>

            <div className="postBottom">
                <p>Məbləğ: {this.props.money}</p>
            </div>
            <div className="postBottom">
                <p>Müddət: {this.props.time}</p>
            </div>
            <div className="postBottom">
                <p>Nömrə: {this.props.mobileNumber}</p>
            </div>
            <div className="postBottom">
                <p>Tarix: {date.getHours() + ":" + date.getMinutes() +", "+date.toLocaleDateString()
                }</p>
            </div>

            <div className="postImage">
            </div>
            <button onClick={() => this.getPDF()}> PDF`i yüklə</button>
            {<div className={showHideClassName}>
              <section className="modal-main">


                <label>
                  FIN:
                  <input type="text" name="name" value={this.state.FIN} onChange={(event) => {console.log(event); this.setState({FIN: event.target.value})} }/>
                  <br/>
                </label>
                <label>
                  Sənədin nömrəsi:
                  <input type="text" name="name" value={this.state.docID} onChange={(event) => {console.log(event); this.setState({docID: event.target.value})} }/>
                  <br/>
                </label>
                  <label>
                    Ad:
                    <input type="text" name="name" value={this.state.firstName} onChange={(event) => this.setState({firstName: event.target.value})}/>
                    <br/>
                  </label>
                  <label>
                    Soyad:
                    <input type="text" name="name" value={this.state.surname} onChange={(event) => this.setState({lastName: event.target.value})}/>
                    <br/>
                  </label>
                  <label>
                    Ata adı:
                    <input type="text" name="name" value={this.state.fatherName} onChange={(event) => this.setState({fatherName: event.target.value})}/>
                    <br/>
                  </label>
                  <label>
                    Doğum tarixi:
                    <input type="text" name="name" value={this.state.dateOfBirth} onChange={(event) => this.setState({dateOfBirth: event.target.value})}/>
                    <br/>
                  </label>

                  <button onClick={()=> this.sendPost(this.state)}>Submit</button>
                  <br/>
                <button onClick={() => this.setState({showModal: false})}>Pəncərəni bağla</button>
              </section>
            </div>}
            <button onClick={this.showModal}>Məlumatları doldur</button>
            <button onClick={() => this.deleteFile(this.state.filename, this.state.fileNameBack)} style={{backgroundColor: "red", borderColor: "red"}}>Sil</button>

        </div>
    )
}
}

export default Post;
