import React from "react";
import SecondC from "./Second.css";
import config from "./config.js";
import axios from 'axios';

class Sixth extends React.Component {
  state = {
    query: "",
    data: [],
    filteredData: [],
    isNumberSelected: false,
    chosenNumber: "",
    percentage: "",
    amount: "",
    time: "",
    fin: "",
    password: "",
    rePass: "",
    token: localStorage.getItem("token")
  };

  handleInputChange = event => {
    const query = event.target.value;

    this.setState(prevState => {
      const filteredData = prevState.data.filter(element => {
        return element.mobileNumber.toLowerCase().includes(query.toLowerCase());
      });

      return {
        query,
        filteredData
      };
    });
  };

  getData = () => {
    fetch('http://'+config.ip+'/api/posts/getUsers',
    {
      headers: {
        'x-access-token': this.state.token
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log("data"+JSON.stringify(data));
        const { query } = this.state;
        const filteredData = data.filter(element => {
          return element.firstName.toLowerCase().includes(query.toLowerCase());
        });

        this.setState({
          data,
          filteredData
        });
      })
      .catch(err =>
      {
        console.log(err)
      });
  };
reset = () => {
    if(this.state.password != this.state.rePass)
    {
        alert("Şifrələr eyni deyil!")
    }
    else
    {
        let data = {
            mobileNumber: this.state.chosenNumber,
            password: this.state.password
        }
        axios.post("http://"+config.ip+"/users/resetPassword", data,
        {
            headers: {'x-access-token': this.state.token },
        }).then(res => alert(res.data.success))
    }
}
  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div className="splitScreen">
      <div className="searchForm">
        <form>
         
          <input
            placeholder="Mobil nömrəyə görə axtar"
            value={this.state.query}
            onChange={this.handleInputChange}
          />
          <br/>
        </form>

        <div>{this.state.filteredData.map(i => <span onClick={() => { this.setState({isNumberSelected: true, chosenNumber: i.mobileNumber})}} key={i.mobileNumber}>{i.mobileNumber} : {i.firstName}, {i.lastName + "\n"} <br/> </span>)}</div>
      </div>
      <div className="dropBox">
      {this.state.isNumberSelected && <div> 
        <form>
          <input placeholder="Şifrə" value={this.state.password} type="number" onChange={(event) => this.setState({password: event.target.value})}/>
          <br/>
          <input placeholder="Şifrə Təkrar" value={this.state.rePass} type="number" onChange={(event) => this.setState({rePass: event.target.value})}/>
          <br/>
      </form>
      <button onClick={this.reset}> Dəyişdir</button>
      </div>
  }
      </div>
      </div>
    );
  }
}
export default Sixth;
