import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

export default class Navbar extends Component {
  state = {
    active: false,
    navbarClass: "collapse navbar-collapse",
    menus: [
      {
        id: 1,
        text: "İstəklər",
        url: "/"
      },
      {
        id: 2,
        text: "Birinci Mərhələ sənədləri",
        url: "/first"
      },
      {
        id: 3,
        text: "İkinci mərhələ sənədləri",
        url: "/second"
      },
      {
        id: 4,
        text: "İmzalanmış müqavilələr",
        url: "/third"
      },
      {
        id: 5,
        text: "Zamin Paneli",
        url: "/fourth"
      },
      {
        id: 6,
        text: "İmzalanmış Zamin müqavilələri",
        url: "/fifth"
      }
    ]
  };

  // navbar toggler button
  handleClick = () => {
    this.state.active
      ? this.setState({
          active: false,
        })
      : this.setState({
          active: true,
        });
  };

  render() {
    return (
      <nav className="navbar">
      <h1 className="navbar-logo">
        AzKredit <i className="fab fa-react"></i>
      </h1>
      <div className="menu-icon" onClick={this.handleClick}>
        <i className={this.state.active ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={this.state.active ? "nav-menu active" : "nav-menu"}>
        {this.state.menus.map(item => {
          return (
            <li key={item.id}>
              <a href={item.url} className={item.cName} style={{color: "#ffffff", fontSize:"16px"}}>
                {item.text} 
              </a>
              <text> | </text>
            </li>
          );
        })}
      </ul>
      <button onClick={() => { localStorage.clear(); window.location.href= "/"}}><h3>Çıxış</h3></button>
    </nav>

    );
  }
}
