import React, { useState}  from 'react';
import './Post.css';
import { Alert } from 'react-alert'

import { saveAs } from 'file-saver';
import axios from "axios";
import './modal.css';
import config from "../config.js";

class Post extends React.Component {

constructor(props)
{
  super(props);
  this.state = {
    showModal: false,
    firstName: "",
    lastName: "",
    fatherName: "",
    docID: "",
    FIN: "",
    mobileNumber: this.props.mobileNumber,
    money: this.props.money,
    time: this.props.time,
    dateOfBirth: "",
    filename: this.props.name,
    isFinished: null

  }
  this.showModal = this.showModal.bind(this);
}
    getPDF ()
    {
      axios.get("http://"+config.ip+"/api/files/download/"+this.props.name, {
        responseType: "blob",
        headers: {
          'x-access-token': this.props.token
        }
      })
      .then(response=>
        {
          console.log(response)
          saveAs(response.data, "test.pdf")
        }
      )

      //fetch("http://192.168.19.1:3000/files/download/"+name,
      //  {
      //      headers: {
      //        'Accept': 'application/pdf'
      //      }
      //  })
      //    .then((res) =>
      //    {
      //      console.log("res");
      //      console.log(res);

      //      saveAs(res.url, "test.pdf");
      //    }
      //  );
    }

    showModal()
    {
      if(this.state.showModal)
      {
        this.setState({showModal: false})
      }
      else{
        this.setState({showModal: true})
      }
    }

    sendPost(state)
    {
        if(state.firstName == "" || state.lastName == "" || state.mobileNumber == "" || state.fatherName == "" || state.docID == "" || state.FIN == "" || state.money == "" || state.dateOfBirth == "" || state.time == "" || state.filename == "" )
        {
          alert("Bütün xanaların doldurulduğundan əmin olun");
        }
        console.log("Send post "+this.props.token)
        debugger
        axios.post("http://"+config.ip+"/api/posts/newPost", {
          token: this.props.token,
          idOfUser: state.mobileNumber,
          name: state.firstName,
          surname: state.lastName,
          fatherName: state.fatherName,
          docID: state.docID,
          finNumber: state.FIN,
          amountOfMoney: state.money,
          time: state.time,
          dateOfBirth: state.dateOfBirth,
          filename: state.filename
        }).then(res =>
          {
            this.setState({showModal: false});
          })
        .catch(err => console.log("Error in send post" + err))
    }


    render()
    {
      const showHideClassName = this.state.showModal ? "modal display-block" : "modal display-none";

    return (
        <div className="postWaiting">
            <div className="postTop">

            </div>
            <div className="postBottom">
                <p>Nömrə: {this.props.mobileNumber}</p>
            </div>

            <div className="postImage">
            </div>
            <button onClick={() => this.getPDF()}> PDF`i yüklə</button>

        </div>
    )
}
}

export default Post;
