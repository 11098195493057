import React from "react";
import axios from "axios";
import SecondC from "./Second.css";
import DragDrop from "./DragDrop";
import config from "./config.js";

class Second extends React.Component {
  state = {
    query: "",
    data: [],
    filteredData: [],
    isNumberSelected: false,
    chosenNumber: "",
    percentage: "",
    amount: "",
    time: "",
    fin: "",
    postTracks : [],
    token: localStorage.getItem("token")
  };

  handleInputChange = event => {
    const query = event.target.value;

    this.setState(prevState => {
      const filteredData = prevState.data.filter(element => {
        return element.mobileNumber.toLowerCase().includes(query.toLowerCase());
      });

      return {
        query,
        filteredData
      };
    });
  };

  getData = () => {
    fetch('http://'+config.ip+'/api/posts/getUsers',
    {
      headers: {
        'x-access-token': this.state.token
      }
    })
      .then(response => response.json())
      .then(data => {
        //console.log("data"+JSON.stringify(data));
        const { query } = this.state;
        const filteredData = data.filter(element => {
          //console.log("Element " + element.firstName)
          return element.firstName.toLowerCase().includes(query.toLowerCase());
        });
        //console.log("Filtered Data")
        //console.log(filteredData)
        this.setState({
          data,
          filteredData
        });
      })
      .catch(err =>
      {
        console.log(err)
      });
  };

  componentDidMount() {
    this.getData();
  }

  getPostTrack(chosenNumber) {
    //console.log("Choosen number")
    //console.log(chosenNumber)
    axios.post('http://'+config.ip+'/api/posts/getPostTracks',{
      mobileNumber: chosenNumber 
    },
    {
      headers: {
        'x-access-token': this.state.token
      }})
      .then(data => 
        {
          //console.log("PostTracks")
          //console.log(data.data)
          this.setState({postTracks: data.data.postTracks})
        })
  }

  render() {
    return (
      <div className="splitScreen">
      <div className="searchForm">
        <form>
         
          <input
            placeholder="Mobil nömrəyə görə axtar"
            value={this.state.query}
            onChange={this.handleInputChange}
          />
          <br/>
        </form>

        <div>
          {
          this.state.filteredData.map(i => 
          <span onClick={() => 
          { 
            
            this.setState({isNumberSelected: true, chosenNumber: i.mobileNumber})
            //console.log(i.mobileNumber)
            //console.log(this.state.chosenNumber)
            this.getPostTrack(i.mobileNumber);
          }
          } 
            key={i.mobileNumber}>
              {i.mobileNumber} : {i.firstName}, {i.lastName + "\n"} <br/> 
              </span>)
          }
        </div>
      </div>
      <div className="dropBox">
      {this.state.isNumberSelected && <DragDrop token={this.state.token} number={this.state.chosenNumber} postTracks={this.state.postTracks} />}
      </div>
      </div>
    );
  }
}
export default Second;
