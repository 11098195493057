import React, { useReducer, useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import "./search.css";
import Table from "./table";
import config from "../config.js";


class Search extends React.Component {

  state ={
    searchValue: "",
    data: [],
    filteredData: [],
    token: localStorage.getItem("token")

  }
 
  componentWillMount = () => {
    this.searchClient();
  }
  handleSearchInputChanges = (event) => {
    const searchValue = event.target.value;

    this.setState(prevState => {
      const filteredData = prevState.data.filter(element => {
        //console.log(element)
        return element.user_id.mobileNumber.toLowerCase().includes(searchValue.toLowerCase());
      });

      return {
        searchValue,
        filteredData
      };
    });
  };

  /*
  searchClient = () => {
    console.log("this client token"+ this.state.token)
    axios
      .get("http://"+config.ip+"/api/postTrack/getAll", {
        headers: {
          "x-access-token": this.state.token
        }
      })
      .then((response) => {
        console.log(this.state.token)
        console.log("Response")
        console.log(response.data)
        this.setState({filteredData: response.data})
        this.setState({data: response.data})    
      })
      .catch((error) => {
          console.log(error)
      });
  };
  */

  searchClient = () => {
    
    axios
      .get("http://"+config.ip+"/api/files/getBackupFiles", {
        headers: {
          "x-access-token": this.state.token
        }
      })
      .then((response) => {
        //console.log(this.state.token)
        //console.log("Response")
        //console.log(response.data)
        this.setState({filteredData: response.data})
        this.setState({data: response.data})    
      })
      .catch((error) => {
          console.log(error)
      });
  };


  render(){
    return (
      <div className="Home">
        <div className="Nav">
          <Header text="Kreditlər" />
          <form className="search">
            <input
              value={this.state.searchValue}
              onChange={this.handleSearchInputChanges}
              placeholder="Mobil Nömrə"
            />
          </form>
        </div>
        <div className="List">
          {
            this.state.data && <Table data={this.state.filteredData} />
          }
        </div>
      </div>
    );
  }
  
};

export default Search;
