import React, { useState } from "react";
import axios from "axios";
import config from "./config.js";

import ReactDOM from "react-dom";

import "./styles-login.css";

function Login({setToken}) {
  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // User Login info
  const database = [
    {
      username: "user1",
      password: "pass1"
    },
    {
      username: "user2",
      password: "pass2"
    }
  ];

  const errors = {
    uname: "invalid username or Password",
    pass: "invalid password"
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    //const userData = database.find((user) => user.username === uname.value);

    // Compare user info
    
    /*if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        setIsSubmitted(true);
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
    */
    const body = {number: uname.value,
    password: pass.value}
    axios.post('http://'+config.ip+'/users/login', body)
    .then(response =>
      {
        if(response.data.incorrect)
        {
            console.log("WRONG PASSWORD")
            setErrorMessages({ name: "uname", message: errors.uname });

        }
        else
        {
            setToken(response.data.token);
            localStorage.setItem("token", response.data.token);
            console.log(response.data.token)
            setIsSubmitted(true);

        }
        
      });
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Mobil Nömrə </label>
          <input type="text" name="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <label>Şifrə </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="button-container">
          <input type="submit" value="Daxil ol"/>
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      <div className="login-form">
        <div className="title"><img src={require('../assets/images/azkredit.png')}></img></div>
        {isSubmitted ? <div>Giriş Uğurludur! <button onClick={window.location.reload()}></button></div> : renderForm}
      </div>
    </div>
  );
}

export default Login;