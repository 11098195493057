import React, { useState, useEffect } from 'react';
import './Feed.css';
import config from "./config.js"
// components
import Post from './Post/Post';
import PostTrack from "./Post/PostTrack";

// database
//import db from '../../firebase'


const Feed = () => {
    const [posts, setPosts] = useState([]);
    const [postT, setPostT] = useState([]);

    const token = localStorage.getItem("token");
    //console.log("Inside feed"+token)
    useEffect(() => {
      getDataFromDb();
      getPostsFromDB();
    //    db.collection('posts').orderBy('timestamp', 'desc').onSnapshot(snapshot => {
    //        setPosts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })));
    //    })
    }, [])
    

    const  getDataFromDb = () => {
              fetch('http://'+config.ip+'/api/files/getAllFiles',
                {
                  headers: {
                    'x-access-token': token
                  }
                })
                  .then((userPosts) => userPosts.json())
                  .then((res) =>
                  {
                    //console.log("FILES")
                    //console.log(res);
                    setPosts( res )
                  }
                );
          };
  const getPostsFromDB = () => {
            fetch('http://'+config.ip+'/api/posts/allUserPosts',
                {
                  headers: {
                    'x-access-token': token
                  }
                })
                  .then((res) => 
                    res.json())
                  .then((res) =>
                  {
                    //console.log("POSTS")
                    //console.log(res);
                    setPostT( res )
                  }
                );
    }
    return(
      <div className="feed">
          <div className="requests">
            <h2>Gələn İstəklər</h2>
          {
                posts.map(post => (
                    <Post
                        key={post._id}
                        postID={post._id}
                        name={post.fileName}
                        nameBack={post.fileNameBack}
                        money={post.amountOfMoney}
                        time={post.time}
                        mobileNumber={post.mobileNumber}
                        token={token}
                        date={post.createdAt}

                    />
                ))
            }
          </div>
          </div>
    )
    /*
    return (
        <div className="feed">
          <div className="requests">
            <h2>Gələn İstəklər</h2>
          {
                posts.map(post => (
                    <Post
                        key={post._id}
                        postID={post._id}
                        name={post.fileName}
                        nameBack={post.fileNameBack}
                        money={post.amountOfMoney}
                        time={post.time}
                        mobileNumber={post.mobileNumber}
                        token={token}
                        date={post.createdAt}

                    />
                ))
            }
          </div>
          
          <div className="responded">
            <h2>Cavablandırılan İstəklər</h2>
            {
              postT.map(pt => (
                <PostTrack 
                  key = {pt._id}
                  name = {pt.firstName}
                  surname={pt.lastName}
                  father={pt.fatherName}
                  doc={pt.docID}
                  fin={pt.fin}
                  money={pt.amountOfMoney}
                  time={pt.time}
                  date={pt.dateOfBirth}
                  isSigned={pt.isSigned}
                  mobNum={pt.mobileNumber}
                  createdAt={pt.createdAt}
                />
              ))
            }
          </div>
        </div>
    )
    */
}

export default Feed;
