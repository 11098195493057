import DashboardIcon from '../assets/icons/dashboard.svg';
import ShippingIcon from '../assets/icons/shipping.svg';
import ProductIcon from '../assets/icons/product.svg';
import UserIcon from '../assets/icons/user.svg';
import done from '../assets/icons/icons8-sign-53.png';
import zamin from '../assets/icons/icons8-user-64.png';
import zamin2 from '../assets/icons/icons8-hand-with-pen-50.png';
import excel from '../assets/icons/icons8-excel-64.png';

const sidebar_menu = [
    {
        id: 1,
        icon: DashboardIcon,
        path: '/',
        title: 'İstəklər',
    },
    {
        id: 2,
        icon: ProductIcon,
        path: '/first',
        title: '1. Mərhələ',
    },
    {
        id: 3,
        icon: ShippingIcon,
        path: '/second',
        title: '2. Mərhələ',
    },
    {
        id: 4,
        icon: done,
        path: '/third',
        title: 'İmzalanmış Müqavilələr',
    },
    {
        id: 5,
        icon: zamin,
        path: '/fourth',
        title: 'Zamin Paneli',
    },
    {
        id: 6,
        icon: zamin2,
        path: '/fifth',
        title: 'İmzalanmış Zamin Müqavilələri',
    },
    {
        id: 7,
        icon: UserIcon,
        path: '/sixth',
        title: 'İstifadəçilər'
    },
    {
        id: 8,
        icon: excel,
        path: '/seventh',
        title: 'Export to Excel'
    }
]

export default sidebar_menu;