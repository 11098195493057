import React, { useState } from "react";
import { Alert } from 'react-alert'
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios';
import config from "./config.js";
const fileTypes = ["PDF"];


function DragDrop({number, token, postTracks}) {

  const [file, setFile] = useState(null);
  const [amount, setAmount] = useState();
  const [time, setTime] = useState();
  const [fin, setFin] = useState();
  const [percentage, setPercentage] = useState(null);
  const [check, setCheck] = useState(null);
  const handleChange = (file) => {
    console.log(file);

    setFile(file);
  };
  const uploadFile = () => {
    if(percentage === "" || time === 0 || amount === 0 || fin ==="" || check=== null)
    {
      alert("Bütün xanaların düzgün doldurulduğundan əmin olun!")
    }
    else
    {
      let data = new FormData();
      data.append('file', file);
      data.append('percentage', percentage);
      data.append('amount', amount);
      data.append('time', time);
      data.append('mobileNumber', number);
      data.append('fin', fin);
      data.append('check', check);
      //console.log(file);
      try {

          axios.post("http://"+config.ip+"/api/files/uploadContract", data,
            {headers: {'x-access-token': token }},
          ).then(res => { console.log("File upload "+res)});

      } catch(error) {
        console.log(error)
      }
    }
  };


  return (
    <div>
      <div style={{display: 'flow',  justifyContent:'center', alignItems:'center'}}>
        <div>
          <strong style={{fontSize: 20}}>Seçilən istifadəçi: {number}</strong>
          <br/>

        </div>
        <div>
          <strong>İstifadəçinin hazırki tələbləri</strong>
          <br/>
          {
            postTracks.map(
              pt=> 
              {
                var date = new Date(pt.createdAt)
                return <label>
                  <input 
                  type="radio"
                  name="stage"
                  id={pt._id}
                  onChange={() => setCheck(pt._id)}/> {date.getHours() + ":" + date.getMinutes() +", "+date.toLocaleDateString()} <br/></label>
              }
            )
            
          }
          <label> <input id={0} type="radio" name="stage" onChange={() => setCheck(0)}/> İlk dəfə</label>
        </div>
      <form>
          <input placeholder="Məbləğ" value={amount} type="number" onChange={(event) => setAmount(event.target.value)}/>
          <br/>
          <input placeholder="Müddət" value={time} type="number" onChange={(event) => setTime(event.target.value)}/>
          <br/>
          <input placeholder="Faiz Dərəcəsi" value={percentage} type="number" onChange={(event) => setPercentage(event.target.value)}/>
          <br/>
          <input placeholder="FIN" value={fin} onChange={(event) => setFin(event.target.value)} />
      </form>
      </div>
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
      <br/>
      <button onClick={uploadFile}> Göndər</button>
      <p> {amount} {time} {number}</p>
    </div>
    </div>
  );
}

export default DragDrop;
